.contactPage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    flex-flow: column;
    padding: 20px;
    text-align: center;
}

.Email {
    width: 100%;
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
}