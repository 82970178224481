.Nav {
    display: flex;
    flex-flow: column;
    background: rgba(0, 0, 0, 0.11);
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
}

.OpenClose {
    box-sizing: border-box;
    flex: 25%;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    height: 100%;
    min-height: 30px;
    border-right: 1px solid whitesmoke;
    padding-top: 5px;
    cursor: pointer;
    border-right: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
}

.TopDates {
    display: flex;
    width: 100%;
}

.BottomDates {
    display: flex;
    width: 100%;
}

.TopLine {
    height: 1px;
    width: 100%;
    background-color: black;

}




