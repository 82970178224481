.TipBlock {
    min-width: 40px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    padding: 4px 18px;
    overflow: visible;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 8px;
    border-radius: 3px;
    font: 16px Georgia, serif;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #fffcfc;
    position: relative;
}

.Active {
    background: rgb(255, 165, 0);
    color: #f2f2f2;
}



@media (max-width: 700px) {
    .TipBlock {
        font: 15px Georgia, serif;
    }
}


@media (max-width:500px){
    .TipBlock {
        padding: 4px 14px;
    }

}