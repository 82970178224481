.SingleStatsContainer {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.SingleStats {
    width: 100%;
    max-width: 310px;
    height: fit-content;
    margin: 0px 1px 20px 1px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    padding: 1px;
}   

.overallButtons {
    width: 100%;
    line-height: 30px;
    order: 0;
    margin: 5px auto 5px auto;
}

ul {
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

.gamesCount {
    width: 100%;
    padding: 10px;
    
}



