.ContentTips {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}



.AllGamesLine {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-top: 10px;
}



.nextPrevBox {
    width: 100%;
    text-align: center;
    height: 20px;
    margin: 15px auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 15px;
}

.nextPrev {
    width: 110px;
    height: 100%;
    line-height: 100%;
    padding: 2px;
    text-align: center;
    margin: 0px 2px;
    cursor: pointer;
}

.noTips {
    margin: 15px auto;
    font: 16px Georgia, serif;
}

.overallButtons {
    width: 100%;
    line-height: 30px;
    order: 0;
    margin-top: 5px;
}

.SortByLine {
    font-weight: 400;
    box-sizing: border-box;
    font-size: 13px;
    align-items: center;
    border-radius: 4px 4px 0 0;
    margin: 10px auto 0px auto;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 22px;

}

.label {
    width: 120px;
    color: black;
    font-weight: 500;
    
}


.dottedLine {
    width: 90%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 16px auto 8px auto;
    color: #ccc;
}

@media screen and (max-width: 600px){
    .SortByLine {
       width: 95%;
       flex-wrap: wrap;
    }

    .dottedLine {
        width: 95%;

    }
}