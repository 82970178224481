.flagImg{
    width: 100%;
    height: 100%;
}

.imgContainer{
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0 15px 0 5px;
    display: inline-block;
    vertical-align: middle;
}