
.linksContainer {
    width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;

}

.allLinks {
    width: 100%;
    display: flex;
    align-items: left;
    flex-flow: column;
    padding: 5px 10px;

}

.allLinks div, .allLinks p {
    line-height: 30px;
}

.header {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    font-size: 18px;
    cursor: pointer;
    color: white;
    margin: 10px auto 30px auto;
    background: linear-gradient(to top, rgba(0,0,0,0.60) 0%,rgba(0,0,0,0.3) 109%);
    padding: 3px;
}