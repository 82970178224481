.bodyApp {
  width: 100%;
  margin: auto;
  background-color: white;
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.bodyContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.scrollTop {
  display: none;
  position: fixed;
  width: 46px;
  height: 46px;
  bottom: 20px;
  right: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 10000000;
  cursor: pointer;
  -webkit-animation: fadeIn 0.3s;
          animation: fadeIn 0.3s;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  opacity: 0.9;
  font-size: 18px;
  color: orange;
  background: linear-gradient(45deg, #2b2a2a, #9b9a9a);
  border-radius: 5px;
}
