.GenerateTicket {
    padding: 2px 2px 5px 2px;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.59);
    -moz-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.59);
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.59);
    border-radius: 5px;
    margin: 0px 0px 30px 0px;
    min-height: 222px;
}

.Header {
    width: 100%;
    text-align: center;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg,#131413 0,#232323 0,#232323 136%);
    padding: 5px;
    color: orange;
    font-weight: 700;
    line-height: 30px;
    border-radius: 5px 5px 0 0;
}


.Games {
    margin-top: 0px;
}

.Unable {
    padding: 10px;
    font-weight: 700;
    color: orange;
}

.Content {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}

.chooseContainer {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
}

.generateButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.generateButtonContainerNo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
}

.generateButton {
    background: orange;
    color: #f2f2f2;
    width: 160px;
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: 6px;
    font: 16px Georgia,serif;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #040404;
}

.generateButtonNo {
    background: orange;
    color: #f2f2f2;
    width: 160px;
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: 6px;
    font: 16px Georgia,serif;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #040404;

}

.line {
    width: 92%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 16px auto 16px auto;
    color: #ccc;
}


.errorMessage {
    width: 100%;
    height: 18px;
    padding: 0px;
    text-align: center;
}