* {
  box-sizing: border-box;
}

.Backdrop {
  width: 100%;
  overflow: auto;
  position: relative;
  text-align: center;
  margin: 0;
  color: rgb(10, 10, 10);
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  z-index: 2;
  background: #f2f2f2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #eaeaea,
    #dbdbdb,
    #f2f2f2,
    #c1beaf
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eaeaea,
    #dbdbdb,
    #f2f2f2,
    #c1beaf
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.spinnerBox {
  text-align: center;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  position: relative;
  margin: 100px 0px 0px 0px;
}

.injuriesContainer {
  width: 94%;
}

.templateBox {
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  color: rgb(10, 10, 10);
  background-color: whitesmoke;
  margin: 0 auto 0 auto;
}

.injuriesContainerTitle {
  text-align: center;
  min-height: 30px;
  font: italic bold 14px Georgia, serif;
  background: #e0dfdf;
  color: rgb(10, 10, 10);
  margin: 0 2px 0 2px;
}

.tipBox {
  width: 80%;
  min-height: 40px;
  margin: auto;
  overflow: auto;
}

.tableContainer {
  margin: 0px auto 20px auto;
}

@media (max-width: 500px) {
  .Backdrop {
    margin-top: 20px;
  }
}
