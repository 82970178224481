.BettingBox {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    min-height: 50px;
    margin: 5px auto 20px auto;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-flow: column;
    min-height: 200px;
    /*border: 1px solid #232323;*/
}

.TipsBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: linear-gradient(180deg,#131413 0,#232323 0,#232323 136%);
    padding: 5px;
    color: orange;
    font-weight: 700;

}

.AllTips {
    width: 100%;
    padding: 2px 2px 5px 2px;
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.59);
    border-radius: 5px;
    margin: 0px 0px 30px 0px;
}

.WeRecomend {
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: linear-gradient(180deg,#131413 0,#232323 0,#232323 136%);
    padding: 5px;
    color: orange;
    font-weight: 700;
    border-radius: 5px 5px 0 0;
}


@media screen and (max-width: 500px) {
    .BettingBox {
        border: none;
    }
}