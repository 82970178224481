*{
    box-sizing: border-box;
}

.tipsLine {
    width: 100%;
    height: min-content;
    display: flex;
    flex-flow: wrap row;
    /* border: 1px solid; */
    border-radius: 5px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    padding-top: 2px;
    position: relative;


}

.tipsLineContainer {
    width: 96%;
    max-width: 500px;
    display: flex;
    flex-flow: wrap row;
    position: relative;
    margin: 10px auto;
}

.firstLine {
    width: 100%;
    display: flex;
}

.tip {
    width: 99%;
    text-align: center;
    font: italic 15px Georgia, serif;
    background: linear-gradient(45deg, #ECEAEA, #BEB9B9);
    padding: 4px;
    border-radius: 4px 4px 0px 0px;
    margin: auto;
}

.chance {
    width: calc(100% - 110px);
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 25px;
}

.chanceBr {
    color: rgba(108, 160, 26, 1.0);
    font-weight: 400;
    display: contents
}

.explanationTxt {
    width: 110px;
    padding-top: 9px;
}

.explanation {
    width: 100%;
}

.explanationLine {
    width: 98%;
    text-align: left;
    padding-left: 10px;
    margin: 5px;
    border-bottom: 1px solid #e5e5e5;
}

.chanceBack {
    width: 160px;
    height: 10px;
    background-color: #c8c6bb;
    border-radius: 10px;
    position: relative;
}

.chanceFront {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 10px;
    background-color: rgba(108, 160, 26, 0.9);
    margin: auto;
    border-radius: 10px;
    display: block;
}


.final {
    position: absolute;
    top: -10px;
    right: -5px;
    width: 50px;
    height: 50px;
    
}

.correct {
    width: 100%;
    height: 100%;
}

.wrong {
    width: 70%;
    height: 70%;
    margin-top: 22%;
}


@media (max-width: 500px) {
    .explanationLine {
        width: 98%;
        margin: auto;
        padding: 3px;
    }

    .tip {
        padding-right: 40px;
    }

    .final {
        top: -10px;
    }
}

