.Items {
    box-sizing: border-box;
    flex: 25%;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    height: 100%;
    min-height: 30px;
    border-right: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    padding-top: 5px;
    cursor: pointer;
}

@media screena and (max-width: 500px){
    .Items {
        height: 25px;
    }
}

.activ {
    box-sizing: border-box;
    flex: 25%;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    height: 100%;
    min-height: 30px;
    border-right: 1px solid whitesmoke;
    padding-top: 5px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
}