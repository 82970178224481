.Container {
    display: flex;
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
    justify-content: center;
    margin-bottom: 40px;
    background-color: white;
}

.checkAllContainer {
    width: 100%;
    min-height: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkAllButton {
    background: orange;
    color: #f2f2f2;
    min-width: 110px;
    padding: 2px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: 3px;
    font: 16px Georgia,serif;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #000000;
}

.saveContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-color: rgb(41, 39, 39);
    position: absolute;
    bottom: 0px;
    left: 0px;

}

.errorM {
    display: flex;
    align-items: center;
    padding: 5px;
    color: chocolate;
}