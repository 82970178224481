
* {
    box-sizing: border-box;
}



.CloseButton {
    position: fixed;
    top: 50px;
    right: 5px;
    width: 50px;
    height: 50px;
    background-color: whitesmoke;
    font-size: 40px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 2px;
    z-index: 3;
    display: block;
}

.CloseSign {
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 500px){
    .CloseButton {
        width: 30px;
        height: 30px;
        font-size: 20px;
    }

}