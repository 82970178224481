.ContentBox{
    min-height: 500px;
    max-width: 800px;
    flex: 70%;
    background-color: white;
    padding: 0px 5px;
    margin: 0px 5px 0px 5px;
    overflow: auto;
    box-sizing: border-box;

}

@media screen and (max-width: 500px){
    .ContentBox {
        margin: 2px;

    }
}