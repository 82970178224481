.Container {
    margin: 0px;
    width: 100%;
    padding: 3px;
    background-color: #5D5D5D;
    font-weight: 400;
    font-size: 13px;
    display: inline-block;
    color: whitesmoke;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 15px;
    margin-top: 5px;
}