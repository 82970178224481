.Settings {
    width: 100%;
    height: 100%;
    min-height: 180px;
    background-color: rgb(41, 39, 39);
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    color: white;
    position: relative;
    background-color: white;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    color: black;
    font-weight: 600;
}