* {
    box-sizing: border-box;
}

.homeBox {
    width: 48%;
    /*max-width: 400px;*/
    padding: 3px;
    min-height: 200px;
    border-radius: 3px;
    color: rgb(10, 10, 10);
    background-color: white;
    margin: 20px auto 20px auto;
    box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.4);
}

.title {
    text-align: center;
    min-height: 30px;
    font: italic bold 18px Georgia, serif;
    background: linear-gradient(45deg, #ECEAEA, #BEB9B9);
    color: rgb(10, 10, 10);
    margin: 0px;
    line-height: 30px;
   
}

.sticky {
    padding: inherit;
  }

@media (max-width: 600px){
    .homeBox {
        width: 99%;
    }

    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
        padding-top: 102px;
      }
    
}
