.pageCenter {
  width: 100%;
  min-height: calc(100% - 45px);
  margin: 45px auto 0px auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .pageCenter {
    flex-direction: column;
  }
}
