
.formBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.overallButtons {
    width: 100%;
    line-height: 30px;
    order: 0;
    margin-top: 5px;
}

.W {
    background-color: #3fac1f;
}

.D {
    background-color: #fe9c34;
}

.L {
    background-color: #f14e43;
}

.standard {
    width: 18px;
    height: 18px;
    display: inline-block;
    text-align: center;
    line-height: 18px;
    font-weight: 400;
    font-size: 13px;
    opacity: 0.88;
    border-radius: 3px;
    margin:1px;
    box-sizing: border-box;
    color: whitesmoke;
}

.wdl {
    width: 100%;
    text-align: center;
    padding-top: 7px;
}