.baner {
    width: 96%;
    margin: 0px auto;
}

.banerFooter {
    width: 100%;
    margin: 0px auto;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.baner img, .banerFooter img {
    width: 100%;
    max-width: 700px;
}

@media (max-width: 500px) {
    .banerFooter {
        flex-flow: column;
    }
}